<template>
  <div class="main">
    <van-nav-bar fixed title="核销历史" left-arrow @click-left="onClickLeft" />
    <div class="searchGigBox">
      <div class="searchBox">
        <div class="stitle" @click="handleBtn">
          {{ sType }}
          <img src="@/assets/images/down.png" alt="" />
        </div>
        <input
          type="text"
          palceholder-calss="palce"
          placeholder="请输入关键词进行搜索"
          v-model="inputCon"
        />
        <div v-if="!inputCon" style="margin-right: 5px" class="topHeight"></div>
        <img
          style="margin-right: 5px"
          v-if="inputCon"
          src="@/assets/images/clear.png"
          alt=""
          @click="inputCon = ''"
        />
        <img src="@/assets/images/search.png" alt="" @click="handleSearch" />
      </div>
    </div>
    <div class="botBox">
      <v-reload
        v-if="recordArr.length > 0"
        style="float: left"
        :on-infinite-load="onInfiniteLoad"
        :parent-pull-up-state="infiniteLoadData.pullUpState"
      >
        <div class="tableBox">
          <table
            border="0"
            rules="all"
            width="100%"
            :cellspacing="10"
            :cellpadding="10"
            style="font-size: 14px; color: #606266; text-align: center"
          >
            <thead style="font-weight: 600">
              <td style="width: 75px" align="left">劵名称</td>
              <td style="width: 100px">劵码</td>
              <td style="width: 75px">核销时间</td>
            </thead>
            <tr v-for="(v, i) in recordArr" :key="i" @click="handleDetail(v)">
              <td class="couponName" style="width: 75px" align="left">
                {{ v.couponName }}
              </td>
              <td style="width: 100px">{{ v.couponNum }}</td>
              <td style="width: 75px">{{ v.useTime }}</td>
            </tr>
          </table>
        </div>
      </v-reload>
      <van-empty v-if="showEmpty" description="暂无数据" />
    </div>

    <!-- 弹窗 -->
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>
<script>
import { Field, Notify, NavBar, Empty, Popup, Picker } from "vant";
import globalStyle from "@/utils/style";
import { verifyRecord } from "@/api/index";
export default {
  components: {
    vanField: Field,
    vanNavBar: NavBar,
    vanEmpty: Empty,
    vanPopup: Popup,
    vanPicker: Picker,
  },
  data() {
    return {
      pageIndex: 1,
      pageCount: 20,
      globalStyle: globalStyle,
      submitLoading: false,
      readState: 0,
      showEmpty: false,
      // 上拉加载的设置
      infiniteLoadData: {
        pullUpState: 0, // 子组件的pullUpState状态 1t提示上拉加载更多 2 加载状态 3 没有更多数据了
      },
      recordArr: [],
      showPicker: false,
      sType: "券名称",
      inputCon: "",
      columns: [],
    };
  },

  created() {
    this.getList();
  },

  methods: {
    getList() {
      return new Promise((resolve, reject) => {
        this.inputCon = this.inputCon.trim().substring(0, 100);
        let data = {
          page: this.pageIndex,
          limit: this.pageCount,
          couponName: this.readState == 0 ? this.inputCon.trim() : null,
          couponNum: this.readState == 2 ? this.inputCon.trim() : null,
          stockName: this.readState == 1 ? this.inputCon.trim() : null,
        };
        verifyRecord(data).then((res) => {
          if (res.data.total >= this.pageCount) {
            this.infiniteLoadData.pullUpState = 1;
            if (res.data.total == this.recordArr.length) {
              this.infiniteLoadData.pullUpState = 3;
            }
          }
          this.recordArr = this.recordArr.concat(res.data.records || []);
          this.showEmpty = this.recordArr.length == 0;
          this.$forceUpdate();
          resolve();
        });
      });
    },

    onInfiniteLoad(done) {
      this.pageIndex = this.pageIndex + 1;
      this.getList().then((res) => {
        done(this.infiniteLoadData.pullUpState);
      });
    },

    // 点击按钮
    handleBtn() {
      this.columns = ["券名称", "活动名称", "券码"];
      this.showPicker = true;
    },

    // 点击搜索
    handleSearch() {
      this.pageIndex = 1;
      this.recordArr = [];
      this.infiniteLoadData.pullUpState = 0;
      this.getList();
    },

    // 弹窗确认选中
    onConfirm(value) {
      this.sType = value;
      if (value == "券名称") {
        this.readState = 0;
      } else if (value == "活动名称") {
        this.readState = 1;
      } else if (value == "券码") {
        this.readState = 2;
      }
      this.pageIndex = 1;
      this.recordArr = [];
      this.infiniteLoadData.pullUpState = 0;
      this.getList();
      this.showPicker = false;
    },

    handleDetail(row) {
      this.$router.push({
        path: "/detail",
        query: {
          verifySnapshotId: row.verifySnapshotId,
        },
      });
    },

    // 点击返回
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
.load-more {
  float: left;
}
</style>
<style lang="scss" scoped>
@import "../../../css.scss";
.main ::v-deep .van-nav-bar .van-icon {
  color: $--font-color;
}
.main {
  float: left;
  width: 375px;
  min-height: 100vh;
  background-color: $--bg-color;
  // padding-bottom: 30px;
  //   border: 0.01px solid rgba(0, 0, 0, 0);
  .botBox {
    float: left;
    width: 100%;
    min-height: calc(100vh - 110px);
    position: relative;
    padding: 0 15px 15px;
    box-sizing: border-box;
    margin-top: 108px;
    .tableBox {
      width: 345px;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 10px;
      padding: 10px;
      thead {
        font-weight: 500;
      }
      tr {
        td {
          font-size: 12px;
          padding: 10px 0;
        }
      }
      td {
        border: none;
      }
      .couponName {
        color: $--theme-color;
      }
    }
  }

  .searchGigBox {
    width: 100%;
    height: 50px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    top: 45px;
    left: 0;
    z-index: 10;
    span {
      width: 36px;
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      color: $--font-color;
      margin: 7px 15px 0 0;
    }
    .searchBox {
      width: 345px;
      height: 36px;
      float: left;
      margin: 7px 0rem 0 15px;
      background-color: #f1f2f5;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // justify-content: flex-start;
      padding: 0 10px;
      box-sizing: border-box;
      border-radius: 5px;
      .stitle {
        width: 72px;
        line-height: 36px;
        font-size: 12px;
        display: flex;
        flex-wrap: nowrap;
      }

      img,
      .topHeight {
        width: 20px;
        height: 20px;
        margin: 10px 0 0 0;
      }
      input {
        width: 208px;
        height: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        background-color: #f1f2f5;
        border: none;
        font-size: 14px;
      }
      input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #c0c5cc;
        font-size: 14px;
      }

      input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #c0c5cc;
        font-size: 14px;
      }

      input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #c0c5cc;
        font-size: 14px;
      }

      input::-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #c0c5cc;
        font-size: 14px;
      }
    }
  }
}
</style>
